



















































































import Component, { mixins } from 'vue-class-component'
import { User } from '@/types'
import { State } from '@/mixins/state'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import { Gender, SubscriptionStatus, SubscriptionApi } from '@/api'
import { formatDate } from '@/util/formatDate'
import Dialog from '@/components/Global/Dialog.vue'
import FormButton from '@/components/Form/Button.vue'

@Component({
  components: {
    Content,
    Toolbar,
    Dialog,
    FormButton
  }
})

export default class UserDetail extends mixins(State) {
  public constructor () {
    super()
    this.showDialog = false
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get sportLocationNames (): Array<string> {
    return this.$store.state.distinctSportLocationNames
  }

  get formattedSportLocationNames (): string {
    return this.sportLocationNames.join('<br>')
  }

  get birthDate () {
    return formatDate(this.currentUser.employeeDetails?.birthDate || '')
  }

  get hasActiveSubscription () {
    return this.currentUser.subscription?.subscriptionStatus === SubscriptionStatus.Active
  }

  get canEditDetails () {
    return this.currentUser?.subscription && this.hasActiveSubscription
  }

  public showDialog: boolean

  public getGender (gender: Gender) {
    switch (gender) {
      case Gender.Female:
        return 'Vrouw'
      case Gender.Male:
        return 'Man'
      case Gender.X:
        return 'X'
      case Gender.Unknown:
        return 'Zeg ik liever niet'
      default:
        return 'Onbekend'
    }
  }

  public async confirmDialog () {
    this.showDialog = false
    this.state = this.states.LOADING
    const api = new SubscriptionApi()
    try {
      await api.subscriptionDeleteDetails({})
      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR
    }
  }

  public closeDialog () {
    this.showDialog = false
  }
}
